import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import DropdownSort from 'gatsby-theme-dettol/src/components/DropdownSort';
import DropdownFilter from 'gatsby-theme-dettol/src/components/DropdownFilter';
import DropdownFilterItem from 'gatsby-theme-dettol/src/components/DropdownFilter/DropdownFilterItem';

import { IProductListFilter } from 'gatsby-theme-dettol/src/components/ProductListFilter/models';
import './ProductListFilter.scss';

const containerClassName = 'dt-product-list-filter';

const ProductListFilter: FC<IProductListFilter> = ({
  shownCount,
  setShowFilters,
  totalCount,
  onSelect,
  options,
  fetchProduct,
  clearFilters,
  showFilters,
  filterBtnText,
  filterTypes,
  applyFiltersText,
  showingOf,
}) => {
  const showing =
    showingOf &&
    showingOf
      .replace(
        '%num%',
        `<strong className="dt-product-list-filter__label-bold">${shownCount}</strong>`
      )
      .replace(
        '%num%',
        `<strong className="dt-product-list-filter__label-bold">${totalCount}</strong>`
      );

  const dropdownFilterItems = useMemo(
    () =>
      filterTypes?.map((item) => (
        <DropdownFilterItem key={item.id} tag={item} fetchProduct={fetchProduct} />
      )),

    [filterTypes, fetchProduct]
  );

  const containerClass = classNames(containerClassName, {
    [`${containerClassName}--opened`]: showFilters,
  });

  return (
    <>
      {filterTypes && (
        <div className={containerClass}>
          <div className="dt-product-list-filter__holder">
            <DropdownFilter
              setShowFilters={setShowFilters}
              showFilters={showFilters}
              clearFilters={clearFilters}
              filterBtnText={filterBtnText}
            />

            <div className="dt-product-list-filter__col">
              <span
                className="dt-product-list-filter__label"
                dangerouslySetInnerHTML={{ __html: showing }}
              />

              <DropdownSort options={options} onSelect={onSelect} />
            </div>
          </div>
          {showFilters ? (
            <div className="dt-filter-product-drop">
              {dropdownFilterItems}
              <Button className="d-md-none" variant="primary" onClick={() => setShowFilters(false)}>
                {applyFiltersText}
              </Button>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default ProductListFilter;
